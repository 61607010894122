body.search
  #mainContent
    padding-top: nav_height_mobile;

    @media breakpoints.large.breakpoint
      padding-top: nav_height;

.searchBar .search-form,
.search-form
  position relative

  input
    padding 0 24px 0 64px
    height 44px
    width 100%
    display flex
    align-items center
    border 1px solid light_gray
    border-radius 22px

  label
    display none

  input[type="submit"]
    position absolute
    top 50%
    left 24px
    transform translateY(-50%)
    display block
    height 18px
    width 18px
    padding 0
    font-size 0
    background center / contain no-repeat url('../dist/img/search-black.svg')
    border none

    &::placeholder
      color #808080
      font-size 16px

.searchBar
  padding-top 40px

.searchResults
  margin-top 40px
  margin-bottom -30px
  > *
    margin-bottom 30px

.searchResult-type
  @extend $h5
  margin-bottom 8px

.searchResult-link
  color rouge
  text-decoration none