.tabs
  display flex
  justify-content center
  align-items stretch
  .tab
    display flex
    justify-content center
    align-items center
    flex-wrap wrap
    cursor pointer
    .tab-text
      text-align center

.tabContent
  display none
  &.active
    display block
