@require '../shared/shared'

.quote_box {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 30px;
  background: cool_gray;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);

  @media breakpoints.medium.breakpoint {
    padding: 40px;
  }

  &:before {
    content: '';
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='25' viewBox='0 0 29 25' fill='none'%3E%3Cpath d='M28.5231 18.7233C28.5231 15.2156 26.0308 13.0925 22.9846 13.0925C22.0615 13.0925 21.4154 13.3694 21.1385 13.554C21.2308 9.76941 24 5.52325 28.0615 4.60018V0.261719C22.8923 0.815563 15.7846 5.52326 15.7846 16.4156C15.7846 21.6771 18.9231 24.2617 22.5231 24.2617C25.9385 24.2617 28.5231 21.7694 28.5231 18.7233ZM12.7385 18.7233C12.7385 15.2156 10.2462 13.0925 7.2 13.0925C6.27692 13.0925 5.63077 13.3694 5.35385 13.554C5.44615 9.76941 8.21538 5.52325 12.2769 4.60018V0.261719C7.10769 0.815563 0 5.52326 0 16.4156C0 21.6771 3.13846 24.2617 6.73846 24.2617C10.1538 24.2617 12.7385 21.7694 12.7385 18.7233Z' fill='%23009DD6'/%3E%3C/svg%3E");
    width: 20px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;

    @media breakpoints.medium.breakpoint {
      width: 29px;
      height: 24px;
    }
  }

  &-text {
    margin-bottom: 24px;
    font-family: nib_font;
    @extend $h6;
    font-weight: 600;
  }

  &-position {
    font-weight: 500;
  }

  &-name {
    font-weight: 450;
  }
}
