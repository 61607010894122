for key, value in colorvars
  .color--{key}
    color: value !important
  .overlay--{key}
    background-color value
  .bg--{key}
    background-color value
  .block--bg{key}
    background-color value
  // .btn--{key}
  //   background-color value
  //   color white
  //   border 2px solid value
  //   &:hover
  //     background-color darken(value,10)
  //     border 2px solid darken(value,10)
  // .btn--{key}Outline
  //   background-color transparent
  //   color value
  //   border 2px solid value
  //   svg *
  //     stroke value
  //   &:hover
  //     background-color value
  //     border 2px solid value
  //     color white
  //     svg *
  //       stroke white
  .block.block--text{key}
    make-color(value)

  .block.block--bg{key}.block--paddingBottomDefault + .block.block--bg{key}.block--paddingTopDefault
    padding-top 0

  // if key is 'navy' || key is 'black'
  //   .block.block--bg{key}
  //     make-color(lightbeige)