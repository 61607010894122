@font-face {
  font-family: "nibpro";
  src: url("/wp-content/themes/ghr/dist/fonts/Nib-Regular-Pro.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/Nib-Regular-Pro.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "nibpro";
  src: url("/wp-content/themes/ghr/dist/fonts/Nib-Italic-Pro.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/Nib-Italic-Pro.woff2") format("woff2");
  font-style: italic;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "nibpro";
  src: url("/wp-content/themes/ghr/dist/fonts/Nib-SemiBold-Pro.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/Nib-SemiBold-Pro.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "nibpro";
  src: url("/wp-content/themes/ghr/dist/fonts/Nib-Bold-Pro.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/Nib-Bold-Pro.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}