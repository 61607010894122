container_width = 1350px

.container
  margin: 0 auto
  max-width: container_width
  padding: 0 40px
  position: relative
  @media breakpoints.palm.breakpoint
    padding: 0 20px

.container--wide
  max-width: 1450px

.container--large
  max-width: 1350px

.container--medium
  max-width: 1110px

.container--blog
  max-width: 1070px

.container--small
  max-width: 1070px

.container--extraSmall
  max-width: 950px

.container--full
  max-width none
