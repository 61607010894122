// .slick-slide
//   padding-bottom: 150px !important
//   @media breakpoints.lap.breakpoint
//     padding-bottom: 120px !important
//   @media breakpoints.palm.breakpoint
//     padding-bottom: 90px !important

.slick-dots
  text-align: center
  padding: 0
  height: auto
  margin: 0
  position: absolute
  list-style: none
  li
    display: inline-block
    margin: 0 10px
    border: none
    width: 20px
    height: 20px
    background-color: white
    outline: none
    cursor: pointer
    border-radius: 50%
    &:first-of-type
      &:last-of-type
        display: none
    &:focus
      outline: none

    &.slick-active, &:hover
      background-color: blue
    &:only-child
      display none
    button
      display none

.slick-slider
  // overflow hidden
  min-width 100%
  width 0

.slick-loading .slick-list
  background: #fff url('../dist/img/ajax-loader.gif') center center no-repeat