*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

body:not(.wp-admin)
  font-family: base_font

body:not(.wp-admin), p, ul, ol
  baseTypography()

.p, .div
  font-family: base_font !important
  font-size: base_font_size !important
  line-height: base_line_height !important
  font-weight: base_font_weight !important
  color: primary_text !important

.siteWrapper, .mainContent, .footer, .block:not(.block--slider)
  h1, h2, h3, h4, h5, h6
    @extend $header_base

  h1
    @extend $h1

  h2
    @extend $h2

  h3
    @extend $h3

  h4
    @extend $h4

  h5
    @extend $h5

  h6
    @extend $h6

  .link, p a, li a
    text-decoration: none
    base-transition()
    color link
    &:hover
      color darken(link, 10)
      text-decoration none
    &:focus
      outline: 0

body .siteWrapper, body .mainContent, body .footer, body .block:not(.block--slider)
  .h1
    @extend $h1

  .h2
    @extend $h2

  .h3
    @extend $h3

  .h4
    @extend $h4

  .h5
    @extend $h5

  .h6
    @extend $h6

  .p_large
    @extend $p_large

  .caption
    @extend $caption

// typekit flicker
// .wf-loading
//   p, h1, h2, h3, h4, h5, h6, .menu-item a
//     visibility: hidden

// .wf-active
//   p, h1, h2, h3, h4, h5, h6, .menu-item a
//     visibility: visible

::selection
  background: primary
  color: white

// br
//   @media breakpoints.palm.breakpoint
//     display none

.weight_thin
  font-weight weight_thin
.weight_lightest
  font-weight weight_lightest
.weight_light
  font-weight weight_light
.weight_regular
  font-weight weight_regular
.weight_medium
  font-weight weight_medium
.weight_semibold
  font-weight weight_semibold
.weight_bold
  font-weight weight_bold
.weight_extrabold
  font-weight weight_extrabold
.weight_black
  font-weight weight_black