// .header
//   overflow hidden
.skipNavigation
  position fixed
  bottom 50%
  right 50%
  transform translate(-5000%, 50%)
  display flex
  flex-wrap wrap
  justify-content center
  max-width 95%
  max-height 95%
  padding 5%
  background-color white
  z-index 1001
  border 2px solid border
  > *
    flex-basis 100%
    max-width 100%
  &:focus, &:focus-within, &.focus
    transform translate(50%, 50%)

.skipNavigation-link
  &:focus
    outline 1px solid primary