.btn
  text-align center
  cursor pointer
  base-transition()
  padding 13px 19px
  display inline-block
  text-decoration none
  appearance none
  border 0
  font-size 16px
  font-family base_font
  font-weight weight_regular
  border-radius 60px
  border 1px solid transparent
  line-height 1
  position relative
  max-width 100%
  &:focus, &:active
    outline 0 !important

.btn--navy
  background-color navy
  color white
  border-color navy
  &:hover, &:focus, &.hover, &.focus
    background-color transparent
    color navy
    border-color navy
    &.btn--large:after
      content url('/wp-content/themes/ghr/dist/img/arrow-navy.svg')

.btn--navyOutline
  background-color transparent
  color navy
  border-color navy
  &:hover, &:focus, &.hover, &.focus
    background-color navy
    color white
    border-color navy

.btn--blue
  background-color blue
  color navy
  border-color blue
  &:hover, &:focus, &.hover, &.focus
    background-color transparent
    color blue
    border-color blue

.btn--blueOutline
  background-color transparent
  color blue
  border-color blue
  &:hover, &:focus, &.hover, &.focus
    background-color blue
    color navy
    border-color blue

.btn--white
  background-color white
  color navy
  border-color white
  &:hover, &:focus, &.hover, &.focus
    background-color transparent
    color white
    border-color white
    &.btn--large:after
      content url('/wp-content/themes/ghr/dist/img/arrow-white.svg')
  &.btn--large:after
    content url('/wp-content/themes/ghr/dist/img/arrow-navy.svg')

.btn--whiteOutline
  background-color transparent
  color white
  border-color white
  &:hover, &:focus, &.hover, &.focus
    background-color white
    color navy
    border-color white

.btn--default
  @extend .btn--navy

.btn--small
  font-size 14px
  padding 0px 17px
  min-width 0

.btn--large
  font-size 24px
  font-weight weight_bold
  border-radius 20px
  padding 33px 30px
  display inline-flex
  align-items center
  align-content center
  justify-content space-between
  min-width 310px
  &:after
    display block
    padding-left 20px
    content url('/wp-content/themes/ghr/dist/img/arrow-white.svg')

.ctaButtons
  margin-top 40px
  display flex
  align-items center
  justify-content flex-start
  flex-wrap wrap
  gap 20px
  .icon
    display inline-block
    color lighten(primary_text, 10)
    base-transition()
    font-size 36px;
    &:hover
      color primary_text
