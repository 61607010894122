.block
  position: relative
  max-width 100%
  img
    max-width 100%
    max-height 100%
    height auto
  &.block--fullHeight
    display flex
    align-items center
    min-height calc(100vh - 29px)
    @media breakpoints.nav.breakpoint
      min-height calc(100vh - 29px)
  &.block--fullHeight--top
    align-items flex-start
  &.block--fullHeight--middle
    align-items center
  &.block--fullHeight--bottom
    align-items flex-end
  &.block--fullHeight--left
    justify-content flex-start
  &.block--fullHeight--center
    justify-content center
  &.block--fullHeight--right
    justify-content flex-end

.block-titles.block-titles--noContent
  .block-title.block-title--hasNoSubTitle, .block-subTitle
    margin-bottom 0 !important

.block-title
  margin-bottom: 10px !important
  &.block-title--hasNoSubTitle
    margin-bottom: 50px !important

.block-subTitle
  margin-top 0
  margin-bottom: 50px !important

.block-title, .block-subTitle
  a
    color blue
    text-decoration none
    display inline-block
    border-bottom 1px solid blue
    margin 0 10px

.block.block--alignLeft
  &.block--simple-content .contentStyled > *
    margin-left 0 !important
    margin-right auto !important
  .container > *, .contentStyled
    text-align: left
  .ctaButtons
    justify-content flex-start

.block.block--alignCenter
  &.block--simple-content .contentStyled > *
    margin-left auto !important
    margin-right auto !important
  .container > *, .contentStyled
    text-align: center
  .ctaButtons
    justify-content center
  .block-title, .block-subTitle
    margin-left auto !important
    margin-right auto !important

.block.block--alignRight
  &.block--simple-content .contentStyled > *
    margin-left auto !important
    margin-right 0 !important
  .container > *, .contentStyled
    text-align: right
  .ctaButtons
    justify-content flex-end

.block.block--alignTitles, .block.block--alignDefault
  .block-title, .block-subTitle
    text-align: center
  .ctaButtons
    justify-content center
  .contentStyled .ctaButtons
    justify-content flex-start
  .block-title, .block-subTitle
    margin-left auto !important
    margin-right auto !important

.mainContent > .block.block--bgdefaultcolor.block--paddingBottomDefault + .mainContent > .block.block--bgdefaultcolor.block--paddingTopDefault
  padding-top 0

.block.block--paddingTopNone
  padding-top 0
.block.block--paddingTopSmall
  padding-top 50px
.block.block--paddingTopMedium, .block.block--paddingTopDefault
  padding-top 80px
  @media breakpoints.palm.breakpoint
    padding-top 60px
.block.block--paddingTopLarge
  padding-top 200px
  @media breakpoints.port.breakpoint
    padding-top 180px
  @media breakpoints.palm.breakpoint
    padding-top 100px

.block.block--paddingBottomNone
  padding-bottom 0
.block.block--paddingBottomSmall
  padding-bottom 50px
.block.block--paddingBottomMedium, .block.block--paddingBottomDefault
  padding-bottom 80px
  @media breakpoints.palm.breakpoint
    padding-bottom 60px
.block.block--paddingBottomLarge
  padding-bottom 200px
  @media breakpoints.port.breakpoint
    padding-bottom 180px
  @media breakpoints.palm.breakpoint
    padding-bottom 100px

.img--topLeft, .img--topleft
  .bgVideo, .bgImage img
    object-position left top

.img--topCenter, .img--topcenter
  .bgVideo, .bgImage img
    object-position center top

.img--topRight, .img--topright
  .bgVideo, .bgImage img
    object-position right top

.img--bottomLeft, .img--bottomleft
  .bgVideo, .bgImage img
    object-position left bottom

.img--bottomCenter, .img--bottomcenter
  .bgVideo, .bgImage img
    object-position center bottom

.img--bottomRight, .img--bottomright
  .bgVideo, .bgImage img
    object-position right bottom

.img--centerLeft, .img--centerleft
  .bgVideo, .bgImage img
    object-position left center

.img--centerCenter, .img--centercenter
  .bgVideo, .bgImage img
    object-position center center

.img--centerRight, .img--centerright
  .bgVideo, .bgImage img
    object-position right center

.img--hasMobile
  @media breakpoints.palm.breakpoint
    display none !important

.img--mobile
  display none !important
  @media breakpoints.palm.breakpoint
    display block !important

.bgImage
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  img
    height 100%
    width 100%
    object-fit cover
    object-position center center

.bgVideoContainer
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  overflow hidden

.offsetVideo .bgVideoContainer
  top 80px
  @media breakpoints.land.breakpoint
    top 0
  @media breakpoints.port.breakpoint
    top 0
  @media breakpoints.palm.breakpoint
    top -200px
  @media (min-width: 1260px) and (max-width: 1420px)
    .bgVideo
      height 101%

.bgVideo
  position absolute
  display block
  object-fit cover
  bottom 50%
  right 50%
  transform translate(50%, 50%)
  height 101%
  width 100%

img
  max-width 100%
  height auto
  width auto

.mainContent > .block:first-child
  &.block.block--paddingTopNone
    padding-top calc(0 + 71px)
  &.block.block--paddingTopSmall
    padding-top calc(50px + 71px)
  &.block.block--paddingTopMedium, &.block.block--paddingTopDefault
    padding-top calc(80px + 71px)
    @media breakpoints.palm.breakpoint
      padding-top calc(60px + 71px)
  &.block.block--paddingTopLarge
    padding-top calc(200px + 71px)
    @media breakpoints.port.breakpoint
      padding-top calc(180px + 71px)
    @media breakpoints.palm.breakpoint
      padding-top calc(100px + 71px)


@media aboveNav
  .mainContent > .block:first-child
    &.block.block--paddingTopNone
      padding-top calc(0 + 116px)
    &.block.block--paddingTopSmall
      padding-top calc(50px + 116px)
    &.block.block--paddingTopMedium, &.block.block--paddingTopDefault
      padding-top calc(80px + 116px)
      @media breakpoints.palm.breakpoint
        padding-top calc(60px + 116px)
    &.block.block--paddingTopLarge
      padding-top calc(200px + 116px)
      @media breakpoints.port.breakpoint
        padding-top calc(180px + 116px)
      @media breakpoints.palm.breakpoint
        padding-top calc(100px + 116px)

body, .siteWrapper
  max-width 100%

body.active
  overflow hidden

*:focus
  outline 0

// *:not(.slick-track):not(.slick-list):not(svg):not(path)
//   base-transition()

[class^="icon-"], [class*=" icon-"]
  base-transition()
  &:before
    base-transition()

// *
//   min-width 0
//   min-height 0

[class^="icon-"]:before, [class*=" icon-"]:before
  base-transition()

[role="button"]
  cursor pointer

html.wpHTML
  margin-top 0 !important

body.admin-bar
  .siteWrapper, nav.nav
    margin-top 32px
  @media (max-width 782px)
    .siteWrapper, nav.nav
      margin-top 46px

.spacer
  height 20px

iframe
  border 0 !important

// iOS text resizing on landscape
html
  -webkit-text-size-adjust: 100%

// @media (prefers-color-scheme: dark)
//   body
//     background-color white

// COLOR OVERRIDES
.block.block--bgnavy
  .btn.btn--blue
      color: white


.facetwp-content {
    @media breakpoints.medium.breakpoint {
			--auto-grid-min-size: 232px;
      display: grid;
		  //grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-template-columns: repeat(auto-fit,232px);
    justify-content: center;
		  column-gap: 44px;
		}
	
		
}