make-color(color)
  p, li, h1, h2, h3, h4, h5, h6, blockquote, div
    color color
  a:not(.btn)
    color color
    &:hover
      color darken(color, 10)

baseTypography()
  font-family base_font
  font-size: base_font_size
  line-height: base_line_height
  font-weight: base_font_weight
  color: primary_text

noButton()
  cursor pointer
  text-decoration none
  appearance none
  padding 0
  background-color transparent
  border 0
  baseTypography()

icon()
  font-family: 'icomoon' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

base-transition()
  transition base_transition

radius(mult = 1)
  border-radius base_radius * mult

visible()
  visibility visible !important
  opacity 1 !important
  *
    visibility visible !important
    opacity 1 !important

hidden()
  visibility hidden !important
  opacity 0 !important
  *
    visibility hidden !important
    opacity 0 !important

show()
  visibility visible !important
  opacity 1 !important
  max-height 5000px
  *
    visibility visible !important
    opacity 1 !important
    max-height 5000px

hide()
  visibility hidden !important
  opacity 0 !important
  max-height 0
  *
    visibility hidden !important
    opacity 0 !important
    max-height 0

hideStyles()
  margin-top 0 !important
  margin-bottom 0 !important
  padding-top 0 !important
  padding-bottom 0 !important
  *
    margin-top 0 !important
    margin-bottom 0 !important
    padding-top 0 !important
    padding-bottom 0 !important

slickArrows()
  .slick-arrows {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    font-size: 0;
    position: relative;

    .slick-arrow {
      -webkit-appearance: none;
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      color navy

      &[aria-disabled="false"] {
        cursor: pointer;
        &:hover, &:active, &:focus {
          color: blue;
        }
      }

      &:last-child {
        margin-left: 20px;
      }

      &[aria-disabled="true"] {
        color: navy;
        opacity: .1;
      }
    }

    .screen-reader-text {
      display none;
    }
  }


// Flexbox

/*flex-grid(gap=0)
	display:flex;
	flex-wrap: wrap;

	--cols: 1;
	--gap: gap;
	--gap-count: calc( var(--cols) - 1 );

	column-gap: var(--gap);

	> * {
		flex-basis: calc((100% / var(--cols)) - (var(--gap) / var(--cols) * (var(--cols) - 1)));
	}
  */
