.history_card {
  border-radius: 30px;
  overflow: hidden;
  display: flex
  flex-direction: column;

  img {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
  }

  &-details {
    flex-grow: 1;
    background: linear-gradient(180deg, rgba(0, 169, 228, 0.00) 57.29%, rgba(0, 169, 228, 0.10) 100%), #F9F9F9;
    padding: 30px 34px 38px;
  }

  &-title {
    font-family: nib_font;
    font-style: italic;
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 10px;

    @media breakpoints.medium.breakpoint {
      font-size: 40px;
    }
  }

  &-thumbnail {
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 3px;
      background: #EBF8FD;
      bottom: 43px;
    }

    &:before {
      left: 0;
      width: 75px;
    }

    &:after {
      left: 75px;
      right: 0;
    }
  }

  &-year {
    width: 110px;
    height: 50px;
    border-radius: 50px;
    background: linear-gradient(270deg, #00A9E4 0%, #00082E 100%);
    color: white;
    font-size: 32px;
    font-family: nib_font;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 2;
  }
}
