/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * ghrhealthcare.com (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: "circular";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Book.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Book.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "circular";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Light.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Light.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: "circular";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Medium.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Medium.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "circular";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Bold.woff") format("woff"), url("/wp-content/themes/ghr/dist/fonts/CircularXXWeb-Bold.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}


/* subset */
/*
@font-face {
  font-family: "CircularXXSub-Book";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Book.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-Book";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Book.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-Light";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Light.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-Light";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Light.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-Medium";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-Medium";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-Bold";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-Bold";
  src: url("/wp-content/themes/ghr/dist/fonts/CircularXXSub-Bold.woff2") format("woff2");
}


*/