.contentStyled
  > *, .styledWysiwyg > *, .paragraphContent > p
    margin 0 0 16px
    &:last-child
      margin-bottom 0 !important
    &:first-child
      margin-top 0 !important

  h1, .h1, h2, .h2, h3, .h3
    margin 0 0 16px !important

  h4, .h4, h5, .h5, h6, .h6
    margin 0 0 16px !important

  .u-inline-block
    margin-right 7px

  .styledButton
    margin-top 30px

  .formStyled
    margin 40px 0

  .styledWysiwyg
    img.alignright
      display inline
      float right
      margin 20px 0 20px 30px
      vertical-align middle
    img.alignleft
      display inline
      float left
      margin 20px 30px 20px 0
      vertical-align middle
  .contentStyled-image, .videoStyled
    margin-top 50px
    margin-bottom 50px
    @media breakpoints.palm.breakpoint
      margin-top 30px
      margin-bottom 30px

  // p
  //   line-height 32px

  ul, ol
    padding-left 25px
    margin 0 0 16px
    li
      margin-bottom 15px

  img
    margin 0 auto
    max-width 100%
    height: auto
    &.image--left
      margin-left 0
    &.image--right
      margin-right 0

  > div img, > a img
    margin 0 auto
    display block
    &.image--left
      margin-left 0
    &.image--right
      margin-right 0

  // Google Hire Code
  .hire-jobs
    margin-top 40px !important
    overflow hidden
    margin-bottom -110px !important
    @media (max-width 649px)
      margin-bottom -70px !important
    iframe
      top -110px
      left -78px
      position relative
      @media (max-width 649px)
        left -15px
        top -40px

.block--simple-content, .block--wysiwyg, .singleContent
  .contentStyled
    a:not(.btn)
      color inherit !important
      text-decoration underline !important

.grid--contentStyledImageGrid
  margin-left -11px !important
  margin-right -11px !important
  > *
    padding 0 11px

  @media breakpoints.palm.breakpoint
    .grid-6of12:last-child .contentStyled-imageGrid-image
      margin-bottom 0 !important

.contentStyled-imageGrid
  margin-top 100px !important
  margin-bottom 100px !important
  @media breakpoints.palm.breakpoint
    margin-top 50px !important
    margin-bottom 50px !important

.contentStyled-imageGrid-image
  @media breakpoints.palm.breakpoint
    margin-bottom 40px !important

.contentStyled-imageGrid-smallText
  margin-top 10px
  font-size 14px
  span
    color primary

.singleContent-content .contentStyled
  h1, h2, h3, h4
    margin 0 0 30px
  p, h5, h6
    margin 0 0 16px
  a
    color primary
    display inline-block
    text-decoration none
    position relative
    &:after
      content ''
      position: absolute
      height 1px
      width 100%
      bottom 0
      left 0
      border-bottom 1px solid border
    &:hover
      text-decoration none

  > .grid > *:last-child
    .contentStyled-image
      margin-bottom 0

.grid--contentStyledMilestones
  margin-bottom -30px

.milestone
  margin-bottom 30px

.milestone-title
  @extend $h4
  font-family header_font

.milestone-text
  margin-top 15px

.image--border
  border 1px solid border

.contentStyled img.image--left, img.image--left
  margin-left 0

.contentStyled img.image--right, img.image--right
  margin-right 0
