.taxonomyList
  position relative
  display flex
  align-items center
  justify-content space-between
  flex-wrap nowrap
  padding: 30px 0
  &:after
    content ''
    position absolute
    bottom 0
    left 0
    height 1px
    width 100%
    border-bottom 1px solid border
  @media breakpoints.nav.breakpoint
    flex-wrap wrap

.taxonomyList-select
  min-width 250px

.taxonomyList-taxonomy
  font-size 18px
  text-transform uppercase
  color primary_text
  base-transition()
  margin: 0 15px
  padding: 5px 0
  position relative
  white-space nowrap
  &:after
    content ''
    base-transition()
    position absolute
    width 100%
    height 1px
    bottom 0
    left 0
    max-width 0
    border-bottom 2px solid primary

  &:hover
    &:after
      max-width 100%
  &.active:after
    max-width 100%

.loadMore
  text-align center
  a
    @extend .btn
    @extend .btn--navy

.taxonomyList-terms
  min-width 260px
  @media breakpoints.nav.breakpoint
    width 100%
    padding 0
    margin-bottom 15px

.taxonomyList-search
  padding-left 20px
  text-align right
  @media breakpoints.nav.breakpoint
    padding 0

.postSearchContainer
  base-transition()
  position relative
  min-width 250px
  display inline-block
  &:after
    icon()
    content '\e90b'
    position absolute
    bottom 50%
    right 8px
    transform translateY(50%)
    pointer-events none
    font-size 15px
  @media breakpoints.nav.breakpoint
    width 100%


input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]).postSearch
  width 100%
  font-family base_font

