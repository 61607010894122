// .block.block--comments
//   background-color: cream

.commentsTitle
  // color: blue3
  margin-bottom: 35px

.commentsContainer
  #commentform .commentField
    margin-bottom: 20px
    &.oneFourth
      width: calc(25% - 12px)
      display: inline-block
      margin-right: 10px
      @media (max-width: 1075px)
        width: calc(50% - 8px)
        &.oneFourth--second
          margin-right: 0
      &.oneFourth--last
        margin-right: 0
      @media breakpoints.palm.breakpoint
        width: 100%
        margin-right: 0
        display: block
  .oneFourth input[type=submit]
    margin-top: 0
    width: 100%
    @media breakpoints.palm.breakpoint
      margin-top: 30px
  input[type=submit]
    background: none
    border: none
    cursor: pointer
    text-align: center
    cursor: pointer
    transition: all 0.2s ease-in-out
    padding: 14px 40px
    display: inline-block
    text-decoration: none
    font-size: 15px !important
    line-height: 1.2
    font-weight: weight_bold
    letter-spacing: 0.1em
    text-transform: uppercase
    // background-color: blue3
    // border: 1px solid blue3
    color: white
    margin-top: 30px
    &:hover
      // background-color: blue2
      // border: 1px solid blue2
      color: white
  textarea
    width: 100%
    height: 150px
    &:focus
      outline: 0

.userComments
  margin: 0 0 40px
  list-style: none
  padding: 0
  p
    margin-bottom: 0
    margin-top: 0
    &:last-child
      margin-bottom: 0
  .userComment
    margin-bottom: 40px
    &:last-child
      margin-bottom: 0

.userComment-name
  // color: blue4
  font-weight: weight_regular

.userComment-time
  font-weight: 300
  margin-bottom: 10px
  color: #666666
  font-style: italic

.userComment-text
  font-weight: weight_light

.comment-subscription-form
  display: none

.subscribe-label
  display: none
