.accordion {
  &-title {
    &.active {
      & + .accordion-body {
        opacity: 1;
        visibility: visible;
        max-height: 1000px;
      }
    }
  }
  &-body {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    base-transition();
  }

  &-title {
    position: relative;
    cursor: pointer;
    background: cool_gray;
    border-radius: 20px;
    padding: 20px 60px 20px 20px;
    font-size: 24px;
    font-family: 'nibpro';
    font-weight: 400;
    line-height: 1.2;
    base-transition();

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 28px;
      height: 28px;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      background center / contain no-repeat url('./img/plus-circle.svg');
    }

    &.active {
      border-radius: 20px 20px 0 0;

      &:after {
        background center / contain no-repeat url('./img/minus-black-circle.svg');
      }

      & + .accordion-body {
        padding: 0 60px 20px 20px;
      }
    }
  }

  &-body {
    background: cool_gray;
    border-radius: 0 0 20px 20px;
    padding: 0 60px 0 20px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
