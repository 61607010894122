.styleguide-colors
  display flex
  flex-wrap wrap
  justify-content center
  align-items center
  margin 60px -15px 30px
  > *
    padding 0 15px
    margin-bottom 30px
    text-align center

.styleguide-color
  height 100px
  width 100px
  border-radius 50%
  border 1px solid black