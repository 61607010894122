// _variables.styl
.header_font
  font-family header_font !important

.base_font
  font-family base_font !important

// _mixins.styl
.visible
  visible()

.hidden
  hidden()

.show
  show()

.hide
  hide()

.hideStyles
  margin-top 0 !important
  margin-bottom 0 !important
  padding-top 0 !important
  padding-bottom 0 !important
  *
    margin-top 0 !important
    margin-bottom 0 !important
    padding-top 0 !important
    padding-bottom 0 !important
