#contact-block {
  .block-title {
    max-width: 1024px;
    line-height: 1.1;
    margin-bottom: 20px !important;
  }

  .block-subTitle {
    max-width: 814px;
    font-size: 18px !important;
    font-weight: 400 !important;
  }

  .contentStyled {
    max-width: 814px;
    margin-left: auto;
    margin-right: auto;
  }

  .gfield {
    padding: 0 10px;
  }

  .gfield--type-chainedselect {
    .gform-grid-row {
      margin: 0 -10px -26px;

      @media breakpoints.medium.breakpoint {
        margin-bottom: 0;
      }

      .gform-grid-col {
        width: 100%;
        margin-bottom: 26px;
        padding: 0 10px;

        @media breakpoints.medium.breakpoint {
          width: 50%;
          margin-bottom: 0;
        }
      }
    }

    select {
      min-width: 0 !important;
    }
  }

  .gform-field-label {
    display: none;
  }

  .gfield--type-radio {
    legend, label, .ginput_container, .gchoice {
      display: inline-block;
    }
    legend {
      display: contents;
      margin: 0;
    }
    input {
      width: 20px;
      height: 20px;
    }
    input, label {
      cursor: pointer;
    }
    .ginput_container {
      margin-left 10px;
    }
  }

  .gfield_required {
    display: none;
  }

  .gform-grid-col {
    padding: 0;
  }

  input, select, textarea {
    border-radius: 8px;
  }

  input, select, textarea, label, legend {
    font-size: 18px;
    font-weight: 300;
  }

  select:disabled {
    background-color: lighten(lightblue, 4);
  }

  .gform_footer {
    display: block;
  }

  .gform_button {
    display: block;
    margin: 0 auto 8px;
  }
}
