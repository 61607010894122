.modal-video-inner
  padding: 0 10px

.modal-video-close-btn
  cursor: pointer
  right: 0
  &:focus
    outline 0

.videoWrapper
  position: relative
  aspect-ratio 16 / 9
  width 100%

.videoWrapper iframe,  .videoWrapper video
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.modalVideo
  cursor pointer
