.cli-plugin-button
  &.cli-plugin-main-button
    @extend .btn
    @extend .btn--navy
    @extend .btn--small
    margin-left 15px !important

#cookie-law-info-bar span
  display flex
  flex-wrap wrap
  align-items center
  justify-content center
