.singleContent {
  padding-top: 126px;
  @media (max-width 1023px) {
    padding-top 70px;
  }

  .container.container--default {
    max-width: 1110px
  }

  .singleContent-body {
    > *:not(.block) {
      margin-left auto
      margin-right auto
      max-width: 1110px
      padding-left: 40px
      padding-right 40px
      position: relative
      @media breakpoints.palm.breakpoint {
        padding-left: 20px
        padding-right 20px
      }
    }
  }

  .singleContent-thumbnailConainer {
    padding-top 80px
    @media breakpoints.palm.breakpoint {
      padding-top 60px
    }

    .singleContent-thumbnail {
      height auto
    }
  }

  .singleContent-thumbnail {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
    height 400px
    img {
      height 100%
      width 100%
      object-fit cover
      object-position center center
    }
  }

  &-details {
    margin-bottom: 20px;
  }

  &-date {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
  }
}

.singleContent-body
  .block.block--bgdefaultcolor.block--paddingBottomDefault + .block.block--bgdefaultcolor.block--paddingTopDefault
    padding-top 0

.siteWrapper, .mainContent, .footer, .block:not(.block--slider)
  .singleContent-body
    h1, h2, h3, h4, h5, h6, p, p a, li
      span
        font-family base_font !important
        font-style normal !important
        font-weight initial !important