:root
  font-size base_font_size

html, body
  margin 0
  padding 0
  width 100%
  height 100%
  font-size base_font_size

.siteWrapper
  min-height 100vh

.siteBody
  contain paint

*, *:before, *:after
  box-sizing: border-box

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section
  display: block

figure
  padding 0
  margin 0

.grid
  display flex
  flex 0 1 auto
  flex-direction row
  justify-content center
  flex-wrap wrap
  margin-left gutter * -1
  margin-right gutter * -1
  margin-bottom -60px
  > *
    padding-left gutter
    padding-right gutter
    @media breakpoints.nav.breakpoint
      flex-basis 100%
      max-width 100%

[class^="grid-offset-"], [class*=" grid-offset-"]
  @media breakpoints.nav.breakpoint
    margin-left 0 !important

.grid-offset-0
  margin-left 0

for col in (1..grid-columns)
  .grid-{col}of12
    flex-basis (col/12) * 100%
    max-width (col/12) * 100%
    margin-bottom 60px
    @media breakpoints.palm.breakpoint
      flex-basis 100%
      max-width 100%

  .grid-offset-{col}
    margin-left (col/12) * 100%

  .grid-order-{grid_column}
    order grid_column

for col in (1..7)
  .grid-{col}
    flex-basis (1/col) * 100%
    max-width (1/col) * 100%
    margin-bottom 60px
    @media breakpoints.palm.breakpoint
      flex-basis 100%
      max-width 100%

for key, value in breakpoints
  @media value.breakpoint
    .grid
      margin-left value.gutter * -1
      margin-right value.gutter * -1
      > *
        padding-left value.gutter
        padding-right value.gutter

    for col in (1..grid-columns)
      .grid-{col}of12--{key}
        flex-basis (col/12) * 100%
        max-width (col/12) * 100%

    for grid_column in (1..grid-columns)
      .grid-order-{grid_column}--{key}
        order grid_column

    for col in (1..7)
      .grid-{col}--{key}
        flex-basis (1/col) * 100%
        max-width (1/col) * 100%
        margin-bottom 60px
        @media breakpoints.palm.breakpoint
          flex-basis 100%
          max-width 100%

.grid--borders
  > *
    border-right 1px solid border
    &:last-child
      border-right 0

.grid--reverse
  flex-direction: row-reverse

.grid--column
  flex-direction: column

.grid--columnReverse
  flex-direction: column-reverse

.grid--justifyContentStart
  justify-content flex-start

.grid--justifyContentEnd
  justify-content flex-end

.grid--justifyContentCenter
  justify-content center

.grid--justifyContentSpaceBetween
  justify-content space-between

.grid--justifyContentSpaceAround
  justify-content space-around

.grid--justifyContentSpaceEvenly
  justify-content space-evenly

.grid--alignItemsStart
  align-items flex-start

.grid--alignItemsEnd
  align-items flex-end

.grid--alignItemsCenter
  align-items center

.grid--alignItemsStretch
  align-items stretch

.grid--alignItemsBaseline
  align-items baseline

.grid--alignContentStart
  align-content flex-start

.grid--alignContentEnd
  align-content flex-end

.grid--alignContentCenter
  align-content center

.grid--alignContentStretch
  align-content stretch

.grid--alignContentSpaceBetween
  align-content space-between

.grid--alignContentSpaceAround
  align-content space-around
