@require '../shared/shared';

.jobs_filter {
  text-align: left;

  &-fields {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 32px;

    @media breakpoints.medium.breakpoint {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &-area {
    display: flex;
    align-items: center;
    padding: 0 60px 0 27px;
    height: 50px;
    background: white;
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='11' viewBox='0 0 20 11' fill='none'%3E%3Cline x1='19.3536' y1='0.353553' x2='9.35355' y2='10.3536' stroke='%2300082E'/%3E%3Cline x1='0.353553' y1='0.646447' x2='9.35355' y2='9.64645' stroke='%2300082E'/%3E%3C/svg%3E");
    background-position-x: calc(100% - 20px);
    background-position-y: 21px;
    background-repeat: no-repeat;
  }

  &-area_name {
    color: gray !important;
    font-size: 18px;
  }

  .jobs_filter-layer {
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 10;
    background: white;
    max-height: 100vh;
    overflow-y: auto;

    @media breakpoints.medium.breakpoint {
      height: auto;
      max-height: 350px;
      border-radius: 0 0 8px 8px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    }

    &:not(:last-of-type) {
      ul {
        li {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='19' viewBox='0 0 10 19' fill='none'%3E%3Cline x1='0.353553' y1='0.646447' x2='9.59042' y2='9.88331' stroke='%23BFBFBF'/%3E%3Cline x1='0.570153' y1='18.1965' x2='8.88333' y2='9.88331' stroke='%23BFBFBF'/%3E%3C/svg%3E");
          background-position-x: calc(100% - 16px);
          background-position-y: 16px;
          background-repeat: no-repeat;
        }
      }
    }

    &:last-of-type {
      ul {
        li {
          position: relative;

          &:before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border: 1px solid light_gray;
            margin-right: 15px;
            flex-shrink: 0;
          }

          &.checked {
            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 15px;
              height: 15px;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'%3E%3Cpath d='M1 8.5L7 14.5L16 1' stroke='%2300082E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      border-top: 1px solid light_gray;

      li {
        display: flex;
        align-items: center;
        height: 50px;
        margin: 0;
        padding: 0 27px;
        border-bottom: 1px solid light_gray;
        cursor: pointer;

        &:hover {
          background-color: rgba(light_gray, 15%);
        }

        &:last-of-type {
          border-bottom: none;
        }

        span {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.2;
          color: navy;
        }
      }
    }
  }

  &-dropdown {
    position: relative;

    .jobs_filter-layer {
      opacity: 0;
      pointer-events: none;
    }

    &.active {
      .jobs_filter-layer {
        &:not(.hide) {
          opacity: 1;
          pointer-events: auto;
        }
      }

      .jobs_filter-area {
        border-radius: 8px 8px 0 0;
      }
    }
  }

  &-layer_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: cool_gray;
    height: 38px;
    padding: 0 27px;
    border-top: 1px solid light_gray;
  }

  &-back,
  &-select_all {
    font-size: 15px;
    font-weight: 450;
    cursor: pointer;
    color: navy !important;
  }

  &-back {
    display: flex;
    align-items: center;
    line-height: 1.2;

    &:before {
      content: '';
      display: block;
      width: 7px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='13' viewBox='0 0 8 13' fill='none'%3E%3Cline x1='6.80441' y1='12.3536' x2='0.488617' y2='6.03776' stroke='%23BFBFBF'/%3E%3Cline x1='6.87992' y1='0.353553' x2='1.19571' y2='6.03776' stroke='%23BFBFBF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 8px;
    }
  }

  &-parent_term_selected {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 27px;
    font-size: 18px;
    line-height: 1.2;
    color: navy !important;
  }

  &-pills,
  &-clear,
  &-apply {
    display: none;

    &:not(.hide) {
      display: flex
      margin-top: 30px;
    }
  }

  &-clear {
    color: navy !important;
    cursor: pointer;
  }

  &-buttons {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &-pills {
    font-size: 0;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }

  &-pill {
    display: inline-flex;
    align-items: center;
    height: 26px;
    padding: 0 12px 0 6px;
    background: navy;
    font-size: 14px;
    font-weight: 450;
    border-radius: 13px;

    .jobs_filter-remove_pill {
      width: 18px;
      height: 18px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='18' viewBox='0 0 19 18' fill='none'%3E%3Cg id='Group 14'%3E%3Ccircle id='Ellipse 8' cx='9.5' cy='9' r='8.5' stroke='white' stroke-linejoin='round'/%3E%3Cpath id='Line 4' d='M5.5 13L13.5 5' stroke='white'/%3E%3Cpath id='Line 5' d='M13.5 13L5.5 5' stroke='white'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height 130px
  }

  &-apply {
    margin-top: 30px;
  }

  &-separator {
    width: 7px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='15' viewBox='0 0 9 15' fill='none'%3E%3Cline x1='0.800819' y1='0.896447' x2='7.90608' y2='8.00171' stroke='white'/%3E%3Cline x1='0.804161' y1='14.3964' x2='7.1989' y2='8.00171' stroke='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &-more {
    color: navy !important;
    font-size: 14px;
    font-weight: 450;
  }
}
