$header_base
  margin: 0
  font-weight: weight_light
  font-family header_font
  color header_text
  span
    font-family nib_font !important
    font-weight weight_regular !important
    font-style italic !important
  em
    font-family nib_font !important
    font-weight weight_regular !important
    font-style italic !important

$h1
  @extend $header_base
  font-size: 66px
  line-height: 72px
  span
    font-size 60px
    line-height: 72px
  span, em
    font-size 90% !important
  @media breakpoints.land.breakpoint
    font-size: 60px
    line-height: 66px
    span
      font-size 56px
      line-height: 66px
  @media breakpoints.port.breakpoint
    font-size: 54px
    line-height: 60px
    span
      font-size 50px
      line-height: 60px
  @media breakpoints.palm.breakpoint
    font-size: 44px
    line-height: 48px
    span
      font-size 40px
      line-height: 48px

$h2
  @extend $header_base
  font-size: 52px
  line-height: 1.2
  @media breakpoints.land.breakpoint
    font-size: 48px
  @media breakpoints.port.breakpoint
    font-size: 44px
  @media breakpoints.palm.breakpoint
    font-size: 40px

$h3
  @extend $header_base
  font-size: 40px
  line-height: 1.2
  span, em
    font-size 95% !important
  @media breakpoints.land.breakpoint
    font-size: 38px
  @media breakpoints.port.breakpoint
    font-size: 36px
  @media breakpoints.palm.breakpoint
    font-size: 34px

$h4
  @extend $header_base
  font-size: 36px
  line-height: 1.3
  @media breakpoints.land.breakpoint
    font-size: 34px
  @media breakpoints.port.breakpoint
    font-size: 32px
  @media breakpoints.palm.breakpoint
    font-size: 30px

$h5
  @extend $header_base
  font-size: 28px
  line-height: 1.4
  span, em
    font-size 95% !important
  @media breakpoints.land.breakpoint
    font-size: 26px
  @media breakpoints.port.breakpoint
    font-size: 24px
  @media breakpoints.palm.breakpoint
    font-size: 22px

$h6
  @extend $header_base
  font-size: 20px
  line-height: 1.6
  font-weight weight_medium
  span
    font-weight weight_bold
  @media breakpoints.land.breakpoint
    font-size: 19px
  @media breakpoints.port.breakpoint
    font-size: 19px
  @media breakpoints.palm.breakpoint
    font-size: 19px

$p_large
  font-size 18px
  line-height 1.6

$caption
  font-size 14px
  line-height 1.2
  margin-top 10px
