@require '../shared/shared'

.spotlight_item {
  border-radius: 20px;
  overflow: hidden;
  height: 100%;

  @media breakpoints.medium.breakpoint {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 32px;
    border-radius: 30px;
  }

  &-details {
    padding: 40px 20px;

    @media breakpoints.medium.breakpoint {
      grid-column: span 5 / span 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 80px 40px;
    }

    br {
      display: inherit;
    }
  }

  &-thumbnail {
    @media breakpoints.medium.breakpoint {
      grid-column: span 7 / span 7;
      order: 2;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &-title {
    @extend $h2;
    margin-bottom: 24px;
  }
  &-desc {
    @extend $h6;
    margin-bottom: 24px;
  }
  &-job {
    font-weight: 500;
  }
}
