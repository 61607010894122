@require '../shared/shared'

.post_card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;

  &-thumbnail {
    img {
      width: 100%;
      display: block;
      aspect-ratio: 1.78;
      object-fit: cover;
      object-position: center;
    }
  }

  &-details {
    flex-grow: 1;
    padding: 20px 30px 30px;
    background: cool_gray;
  }

  &-tag {
    display: inline-flex;
    padding: 0 8px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: white;
    color: blue;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 450;
    margin-bottom: 24px;
  }

  &-title {
    @extend $h5;
    font-family: nib_font;
  }
}
