.overlay
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  z-index -1

.overlay--bg
  z-index -4

.modal
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  z-index 3

.modalContainer
  position relative
  height 100%

body.modalActive
  overflow hidden
