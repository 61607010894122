.posts-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 40px;

  @media breakpoints.medium.breakpoint {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .post_card {
    height: 100%;
  }
}
