.pagination {
  margin-top 60px
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media breakpoints.large.breakpoint {
    flex-direction: row;
    gap: 32px;
  }

  &:empty {
    margin: 0;
  }
}

.page-numbers {
  height: 34px;
  width: 34px;
  color: black;
  border-radius: 50%;
  @extend $h5;
  border: 1px solid transparent;
  base-transition()
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration none

  &.current,
  &:hover {
    border: 1px solid navy;
  }

  &.next,
  &.prev {
    width: 48px;
    height: 48px;
    font-size: 0;
    background: center / contain no-repeat url('../dist/img/next.svg');
    border: none;
  }


  &.prev {
    transform: rotate(180deg);

    @media breakpoints.large.breakpoint {
      margin-right: 48px;
    }
  }

  &.next {
    @media breakpoints.large.breakpoint {
      margin-left: 48px;
    }
  }
}