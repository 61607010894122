// _colors.styl
navy = #00082E
blue = #009DD6
lightblue = #DBECF2
mediumblue = #222F88
palegreen = #DAF9E9

header_text = navy
primary_text = navy
primary = navy
secondary = blue
tertiary = lightblue
gray = #323941
border = #E0E0E0
link = mediumblue
white = #FFFFFF
black = #000000
placeholder = navy
cool_gray = #EBF8FD
light_gray = #BFBFBF
green = #08D66E

colorvars ?= {
  header_text: header_text,
  primary_text: primary_text,
  navy: navy,
  blue: blue,
  lightblue: lightblue,
  palegreen: palegreen,
  cool_gray: cool_gray,
  light_gray: light_gray,
  gray: gray,
  border: border,
  link: link,
  white: white,
  black: black,
  placeholder: placeholder,
  green: green,
}

.block--bglightbluegradient {
  background: linear-gradient(180deg, rgba(219, 236, 242, 0.00) 0%, #DBECF2 100%);
}

.block--bgbottomrightbluegradient {
  background: radial-gradient(142.44% 105.46% at 100% 100%, #222F88 0%, rgba(0, 157, 214, 0.60) 20.83%, rgba(255, 255, 255, 0.00) 80.21%), #FFF;
}

.block--bgbottomrightorangegradient {
  background: radial-gradient(142.44% 105.46% at 100% 100%, rgba(34, 47, 136, 0.80) 0%, rgba(255, 69, 0, 0.60) 20.83%, rgba(255, 255, 255, 0.00) 80.21%), #FFF;
}

.block--bgbottomrightgreengradient {
  background: radial-gradient(142.44% 105.46% at 100% 100%, rgba(8, 214, 110, 0.60) 0%, rgba(0, 157, 214, 0.10) 58.91%, rgba(255, 255, 255, 0.00) 100%), #FFF;
}

.block--bgbottomcenterbluegradient {
  background: radial-gradient(108.36% 108.36% at 52.5% 114.2%, #222C84 0%, rgba(0, 157, 214, 0.60) 42.36%, rgba(0, 157, 214, 0.00) 100%), #FFF;
}

.block--bgbottomcenterorangegradient {
  background: radial-gradient(108.36% 108.36% at 52.5% 114.2%, #222C84 0%, rgba(255, 69, 0, 0.60) 42.36%, rgba(255, 69, 0, 0.00) 100%), #FFF;
}

.block--bgbottomcentergreengradient {
  background: radial-gradient(108.36% 108.36% at 52.5% 114.2%, #222C84 0%, rgba(0, 170, 229, 0.76) 33.33%, rgba(8, 214, 110, 0.50) 66.67%, rgba(8, 214, 110, 0.00) 100%), #FFF;
}
.block--bgbottombluegradient {
  background: linear-gradient(0deg, #009DD6 0%, rgba(0, 157, 214, 0.40) 28.07%, rgba(255, 255, 255, 0.00) 94.16%), #FFF;
}

.block--bgbottomorangegradient {
  background: linear-gradient(0deg, rgba(34, 47, 136, 0.40) 0%, rgba(255, 69, 0, 0.40) 28.07%, rgba(255, 255, 255, 0.00) 94.16%), #FFF;
}

.block--bgbottomgreengradient {
  background: linear-gradient(0deg, rgba(8, 214, 110, 0.40) 0%, rgba(0, 157, 214, 0.40) 28.07%, rgba(255, 255, 255, 0.00) 94.16%), #FFF;
}
