#subscribe-block {
  .ivwc-media {
    img {
      width: 250px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 30px;

      @media breakpoints.large.breakpoint {
        margin-right: 0;
      }
    }
  }
  .ivwc-content {
    @media breakpoints.large.breakpoint {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  .paragraphContent {
    &.large p {
      font-size: 18px;
      font-weight: 400;
    }
    br {
      display: block;
    }
  }

  .gform_fields {
    margin-left: -16px;
    margin-right: -16px;
    align-items: start;
  }
  .gfield {
    margin-bottom: 26px;
    padding-left: 16px;
    padding-right: 16px;

    &:first-of-type {
      @media breakpoints.medium.breakpoint {
        flex-basis: 300px;
        max-width: 300px;
      }

      @media breakpoints.large.breakpoint {
        flex-basis: 400px;
        max-width: 400px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;

      @media breakpoints.medium.breakpoint {
        flex-basis: calc(100% - 300px);
        max-width: calc(100% - 300px);
      }

      @media breakpoints.large.breakpoint {
        flex-basis: calc(100% - 400px);
        max-width: calc(100% - 400px);
      }
    }

    @media breakpoints.large.breakpoint {
      margin-bottom: 0;
    }
  }
  button {
    border: 1px solid blue;
    padding: 0 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:hover {
      border-color: blue;
      background: transparent;
    }

    @media breakpoints.medium.breakpoint {
      width: auto;
      display: inline-flex;
    }
  }
  .gform_ajax_spinner {
    margin-top: 8px;

    @media breakpoints.medium.breakpoint {
      display: inline-block;
      margin-left: 20px;
    }
  }
  .gfield--type-submit {
    display: block !important;
  }
  input {
    font-weight: 300;
    border-radius: 8px;
    padding: 0 22px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .gform_validation_errors {
    padding: 16px 20px;
  }
}
