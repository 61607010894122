.header
  position fixed
  top 0
  left 0
  right 0
  z-index 100

body:not(.showlinks) .nav
  &.headroom--not-top,
  &.headroom--menu-active
    &.headroom--unpinned
      .nav-lower
        @media breakpoints.large.breakpoint
          hide()
          hideStyles()

.nav
  padding 10px 0
  base-transition()
  *
    base-transition()
  &.headroom--not-top,
  &.headroom--menu-active
    background-color white
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.25)

  > .container
    display flex
    align-items center
    justify-content space-between
    flex-wrap nowrap

.nav-logo
  display inline-block
  img, svg
    height 51px
    width auto
    display block

.nav-buttons
  display none
  gap 0 24px

  @media breakpoints.large.breakpoint
    display flex

.nav-lower
  margin-top 20px

.nav-primaryContainer
  width 100%

  .nav-buttons,
  .search-form-wrapper
    display none

  .search-form-wrapper
    position relative

    &.active
      display block

    .close
      position absolute
      right 14px
      top 50%
      transform translateY(-50%)
      width 24px
      height 24px
      background center / contain no-repeat url('./img/close-circle.svg')
      cursor pointer

  .nav-primary-wrapper
    &.disabled
      display none

.nav-primary
  @extend .u-plainList
  display flex
  align-items center
  justify-content space-between
  gap 20px
  margin 0 0 0 -20px

  & > .menu-item
    display block

    & > a
      display inline-block
      text-decoration none
      font-weight weight_regular
      color navy
      line-height 1
      padding 12px 20px
      margin -12px 0 0

      &:hover
        color inherit

    &.menu-item-has-children
      position relative

      & > a
        transition none

      &.show
        & > a
          background navy
          color white
          border-radius 10px 10px 0 0

        & > ul.sub-menu
          show()

          a
            color inherit

    &.search
      font-size 0

      a
        display block
        width 58px
        height 18px
        background center / contain no-repeat url('./img/search-black.svg')

  ul.sub-menu
    hide()
    position absolute
    list-style none
    padding 8px 0
    background white
    width auto
    border-radius 0 10px 10px 10px
    @media (max-width 1023px)
      position static

    > li.menu-item
      relative
      &:not(:last-of-type)
        &:after
          content ''
          display block
          margin 0 20px
          border-bottom 1px solid light_gray

      a
        display block
        padding 12px 20px
        color black
        white-space nowrap

.nav-menu
  display none

@media breakpoints.nav.breakpoint
  .nav-menu
    display flex
    align-items center
    justify-content center
    text-decoration none
    color primary_text
    height 30px
    width 30px
    base-transition()
    &:before
      base-transition()
      content ''
      display block
      width 28px
      height  25px
      background center / contain no-repeat url('./img/menu.svg')

    &.active:before
      height 28px
      background center / contain no-repeat url('./img/close.svg')

  .nav-primaryContainer
    position: fixed
    top nav_height_mobile
    left 0
    right 0
    bottom 0
    max-height calc(100vh - nav_height_mobile)
    hidden()
    background-color white
    z-index 1000

    &.active
      visible()

    .nav-buttons
      display flex
      flex-wrap wrap
      gap 20px
      padding 0 0 20px
      border-bottom 1px solid #D9D9D9
      margin-bottom 20px

      a
        width calc(50% - 10px)

        // &:last-of-type
        //   width 100%

    .search-form-wrapper
      display block

      .close
        display none

  .nav-primary-wrapper
    max-height calc(100vh - 344px)
    overflow auto
    padding-right 20px
    margin-right -20px
    padding-bottom 20px
    border-bottom 1px solid #D9D9D9
    margin-bottom 20px

  .nav-primary
    display flex
    flex-direction column
    align-items stretch
    gap 10px
    margin 0

    & > .menu-item
      & > a
        display flex
        justify-content space-between
        align-items center
        padding 20px
        background cool_gray
        color navy !important
        border-radius 20px
        margin 0

      &.menu-item-has-children
        position relative

        & > a
          padding 14px 20px

        a
          .arrow
            display block
            width 28px
            height 28px
            background center / contain no-repeat url('./img/plus-circle.svg')

        &.show
          & > a
            background cool_gray
            color navy !important
            border-radius 20px

            .arrow
              background center / contain no-repeat url('./img/minus-circle.svg')

      &.search
        display none

    ul
      position static
      padding 20px 0

      li
        &:after
          display none !important

        &:not(:last-of-type)
          margin-bottom 3px

        a
          padding 0 20px


// Nav Page Overrides
body.white
  .nav.headroom--top
    .nav-logo
      filter: brightness(0) invert(1);
    .nav-primary > .menu-item > a
      color white
    @media (min-width 1024px)
      .btn--blue
        @extend .btn--white

body.white
  nav.headroom--not-top
    .nav-primary > .menu-item.search a
      background center/contain no-repeat url("./img/search-black.svg")

body.solid
  .nav
    background-color white
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.25)

body.white
  .logo-white
    display none
  .nav-primary > .menu-item.search a
    background center/contain no-repeat url("./img/search.svg")
  .nav.headroom--top
    .nav-logo
      img.logo-white
        display block
      img:not(.logo-white)
        display none