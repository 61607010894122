#ajax-loader
  display none

.postItem
  display: flex
  flex-wrap: wrap
  radius()
  overflow hidden
  base-transition()
  margin-bottom: 40px
  text-decoration none
  *
    text-decoration none
  &:hover
    box-shadow: 0 10px 15px -10px rgba(30,45,62,.21), 0 5px 40px -10px rgba(31,44,60,.1)

.postItem-image
  padding-bottom: ((222px / 398px) * 100%)%
  position: relative
  background-position: center center
  background-repeat: no-repeat
  background-size: cover
  background-color secondary
  // overflow: hidden
  width: 100%
  &.postItem-image--single
    margin: 0 -80px 50px
    width: calc(100% + 160px)
    @media (max-width: 900px)
      width: 100%
      margin: 0 0 50px

.postItem-info
  width: 100%
  padding: 30px
  background-color: white
  color: primary_text
  border 1px solid border
  border-top 0
  border-radius 0 0 base_radius base_radius

.postItem-title
  base-transition()
  font-weight weight_bold

.postItem-taxonomy
  font-size: 12px
  font-weight weight_bold
  color: primary
  letter-spacing: .3px
  text-transform: uppercase
  margin-bottom: 15px
  display: block
  cursor: pointer
  padding-bottom 2px
  position relative
  base-transition()
  display inline-block
  &:after
    content ''
    border-bottom 2px solid primary
    position absolute
    left 0
    bottom 0
    right 0
    opacity 0
    base-transition()

.postItem.postItem--featured
  .featuredText
    display: none

  @media (min-width: 767px)
    margin-bottom: 0
    flex-wrap: nowrap
    .postItem-image
      order: 2
      width: 60%
      padding-bottom: 40%

    .postItem-info
      order: 1
      width: 40%
      text-align: left
      padding: 45px
      border 1px solid border
      border-top 1px solid border
      border-right 0
      border-radius base_radius 0 0 base_radius
      .postItem-title
        @extend $h2
        font-weight weight_bold

      .postItem-taxonomy
        margin-bottom 25px

      .featuredText
        display: block
        letter-spacing: 0
        text-transform: none
        font-size: 18px
        margin-top: 30px
        color: #333333
        font-weight: 400
        line-height: 1.5



    .postItem-author-photo
      height 60px
      width 60px
      margin-right 20px
      img
        max-height 60px

.postItem-author
  display flex
  align-items center
  justify-content flex-start
  flex-wrap nowrap
  margin-top 50px

.postItem-author-photo
  border-radius 50%
  overflow hidden
  height 50px
  width 50px
  position: relative
  margin-right 10px
  img
    max-height 50px
    width auto
    display block
    position absolute
    bottom 50%
    right 50%
    transform translate(50%,50%)

.postItem-author-info
  width auto
  flex 1

.postItem-author-name
  color primary_text

.postItem-author-title
  font-size 14px
