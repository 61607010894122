@require '../shared/shared'

.icon_point {
  @media breakpoints.medium.breakpoint {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 32px;
    align-items: center;
  }

  &-thumbnail {
    margin-bottom: 20px;

    @media breakpoints.medium.breakpoint {
      grid-column: span 5 / span 5;
      display: flex;
      justify-content: end;
      margin: 0;
    }

    img {
      display: block;
      width: 225px;
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center right;
      margin: 0 auto;

      @media breakpoints.medium.breakpoint {
        margin: 0;
      }
    }
  }

  &-details {
    text-align: center;

    @media breakpoints.medium.breakpoint {
      grid-column: span 6 / span 6;
      text-align: left;
    }
  }
  &-title {
    @extend $h3;
    margin-bottom: 16px;
  }
  &-desc {
    @extend $p_large;
  }
}
