@require '../shared/shared'

.icon_card {
  &-thumbnail {
    padding: 0 13px;
    margin-bottom: 10px;

    img {
      width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
    }
  }
  &-title {
    @extend $h3;
    margin-bottom: 16px;
  }
  &-desc {
    @extend $p_large;
  }
}
