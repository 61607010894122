#quick-apply-block {
  .block-title {
    max-width: 1024px;
    line-height: 1.1;
    margin-bottom: 20px !important;
  }

  .block-subTitle {
    max-width: 814px;
    font-size: 18px !important;
    font-weight: 400 !important;
  }

  .contentStyled {
    max-width: 814px;
    margin-left: auto;
    margin-right: auto;
  }
}
