.mainContent, .footer, .block:not(.block--slider)
  select, textarea, input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):not([type=search]), .gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea
    box-sizing: border-box
    -webkit-appearance: none
    box-shadow none
    outline none
    border none
    font-family base_font
    padding: 20px 26px
    margin: 0
    outline: 0
    width: 100%
    border: 0
    font-size base_font_size
    line-height: 1.2
    font-weight: weight_regular
    color: primary_text
    width: 100%
    border-radius 5px
    background-color white
    base-transition()
    font-family base_font
    border 1px solid border
    &:focus
      border-color darken(border,30)

  textarea, .gform_wrapper.gravity-theme .gfield textarea.large
    height 100px
    font-family base_font

  select
    background-image: url('../dist/img/dropdown.svg') !important
    background-size: 16px 9px
    background-repeat: no-repeat
    background-position: center right 10px
    font-family base_font

  ::-webkit-input-placeholder
    color: placeholder

  ::-moz-placeholder
    color: placeholder

  :-ms-input-placeholder
    color: placeholder

  :-moz-placeholder
    color: placeholder

  .checkbox, .gfield_checkbox > div {
      list-style: none;
      position: relative;
      cursor: pointer;
      color: primary_text;
      &:hover {
        label {
          color: primary_text;
        }
      }

      label {
          display: inline-block;
          max-width: 100%;
          padding-left: 32px;
          font-size: 16px;
          line-height: 26px;
          font-weight: 400 !important;
          color: primary_text;
      }
      input[type="checkbox"] {
          background-image: url('../dist/img/form_checkbox_normal.svg');
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: left center;
          left: 0;
          top 4px
          position: absolute;
          appearance:         none;
          width: 20px !important;
          height: 20px !important;
          outline 0 !important

          &:checked {
              background-image: url('../dist/img/form_checkbox_active.svg');
          }
      }
  }

  &.block--simple-content .gfield_checkbox {
    display flex
    flex-wrap wrap
    gap 10px 20px
    > * {
      flex-basis calc(50% - 10px)
      max-width calc(50% - 10px)
      @media breakpoints.palm.breakpoint {
        flex-basis 100%
        max-width 100%
      }
    }
  }

  &.block--bgnavy {
    .checkbox, .gfield_checkbox > div {
      label {
        color white
      }
    }

    .btn--default {
      @extend .btn--blue
    }
  }

  .radio {
      position: relative;
      label {
          padding-left: 26px;
          font-weight: 400 !important;
          font-size: 16px;
          line-height: 26px;
          color: primary_text;
      }
      input[type="radio"] {
          background-image: url('../dist/img/form_radio_normal.svg');
          background-size: 22px 22px;
          background-repeat: no-repeat;
          background-position: left center;
          left: 0;
          position: absolute;
          appearance:         none;
          width: 22px !important;
          height: 22px !important;
          outline 0 !important
          &:checked {
              background-image: url('../dist/img/form_radio_active.svg');
          }
      }
  }

// GRAVITY FIELDS
body .mainContent, body .block:not(.block--slider), body .footer
  .gform_wrapper ul.gfield_checkbox li
    @extend .mainContent .checkbox

  fieldset
    border 0

  .gform_wrapper ul.gfield_radio li
    @extend .mainContent .radio

  .gform_wrapper textarea.medium
    @extend .mainContent textarea

  .gform_wrapper ul.gform_fields li.gfield select
    @extend .mainContent select

  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])
      @extend .mainContent input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):not([type=search])


// GRAVITY OVERRIDES
body .mainContent, body .block:not(.block--slider), body .footer
  .gform_wrapper
    padding: 0
    margin: 0

  .gform_wrapper.gravity-theme .gform_fields
    width auto
    grid-row-gap 0
    grid-column-gap 0
    display flex
    flex-wrap wrap
    justify-content flex-start
    margin 0 -5px -26px
    > *
      flex-basis 100%
      max-width 100%
      padding 0 10px
      margin-bottom 26px
      // .ginput_complex.gfield_chainedselect
      &.half, &.gfield--width-half
        flex-basis 50%
        max-width 50%
        @media breakpoints.palm.breakpoint
          flex-basis 100%
          max-width 100%

  .gform_wrapper.gravity-theme .ginput_complex:not(.ginput_container_address) span:not([style*="display:none"]):not(.ginput_full)
    padding-right 0
  .gform_wrapper .gform_footer
    padding: 0
    margin: 0
    margin-top 30px

  .gform_wrapper ul.gform_fields li.gfield
    margin: 0
    padding: 0

  .gform_wrapper .gfield_label, .ginput_container_address label
    display none

  .ginput_complex
    span
      display block
      margin-bottom 26px
      &:last-child, &:nth-last-child(2)
        margin-bottom 0

  // .gform_wrapper .top_label div.ginput_container
  //   margin: 0 0 20px !important

  .gform_wrapper .field_description_below .gfield_description
    padding-top 0

  .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half)
    max-width 100% !important

  .gform_wrapper li.gfield.gfield_error, body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning
    border-top: 0
    border-bottom: 0
    background-color transparent

  // FORM SHOW LABELS
  .form-showLabels
    .gform_wrapper .gfield_label, .ginput_container_address label, .ginput_complex label
      display block
      margin 0 0 10px
      font-size 16px
      font-weight weight_regular
    .ginput_complex span
      margin-bottom 26px !important
    .gfield_required
      display none

#gform_wrapper_21 .gform_heading {
  display: none;
}

#gform_21 {
  display: flex;
  flex-wrap: wrap;
}

#gform_21 > * {
  flex-basis: 100%;
  max-width: 100%;
}

#gform_21 .gf_progressbar_wrapper {
  order: 1;
}

.form-hideLabels
  .gform_wrapper.gravity-theme .ginput_complex label, .gform_wrapper.gravity-theme .ginput_complex legend
    display none