.nav-footer
  @extend .u-plainList

.footer-logo {
  display: inline-block;

  img {
    display: block;
    max-height: 51px;
    width: auto;
  }
}


.siteFooter {
  background-color: #323941;  
  padding-bottom: 38px;
  overflow-x: hidden

  .footer-container {
    .footer-wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 46px;
      margin-bottom: 60px;
      max-width: 100%;
      width: 100%;

      @media (max-width: 1099px) {
        flex-wrap: wrap;
        padding-top: 38px;
        margin-bottom: 40px;
      }

      .logo-wrap {
        max-width: 200px;

        @media (min-width: 768px) and (max-width: 1023px) {
          margin-right: 20px;
          max-width: 100px;
        }

        @media (max-width: 1099px) {
          margin-right: 0;
          width: 100%
          max-width 100%
          margin-bottom: 40px;
        }

        a {
          img {
            height 51px
            width auto
            display block
          }
        }
      }

      .nav-footer-container {
        padding 0 5%
        @media (max-width: 1099px) {
          padding 0
        }
        .nav-footer {
          display: flex;
          list-style: none;

          @media (max-width: 1099px) {
            flex-wrap: wrap;
            gap 30px
          }

          @media breakpoints.palm.breakpoint {
            gap 0 20px;
            > * {
              flex-basis calc(50% - 10px)
              max-width calc(50% - 10px)
            }
          }

          & > li {
              margin-right: 80px;
              width 150px
              max-width 100%
              @media (min-width: 768px) and (max-width: 1023px) {
                margin-right: 20px;
              }

              &:last-child {
                margin-right: 0;

                @media (min-width: 768px) and (max-width: 1023px) {
                  margin-right: 20px;
                }
              }

              @media (max-width: 1099px) {
                margin-right: 0 !important;
                // width: 100%;
                max-width: 100% !important;
                margin-bottom: 40px;
              }

              @media breakpoints.palm.breakpoint {
                width 100%
              }


              & > a {
                color: #FFF;
                font-size: 16px;
                font-weight: weight_medium;
                line-height: 18px;  
                margin-bottom: 15px;
                display: block;
              }

              ul.sub-menu {
                padding: 0;
                list-style: none;

                li {
                  margin-bottom: 15px;
                  a {
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 18px;
                    display: block;
                  }
                }
              }
          }
        }
      }

      .footer-connect-title {
        display: block;
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 20px;
      }

      .footer-locationsContainer {
        margin-top 35px
      }

      .footer-locations {
        color white
        margin-top -6px
      }

      .social-icon {

        .wrap-social {
          display: flex;
          align-items: center;

          a {
            margin-right: 28px;

            div {
              height: 24px;
              width 24px;
              display: block;
              background white
              base-transition()
              &.youtube {
                width 32px
              }
              &.facebook {
                width: 12px
              }
              &:hover {
                background blue
              }
            }
          }
        }
      }
    }

    .bottom-footer {
      display: flex;
      justify-content: center;

      @media (max-width: 1099px) {
        flex-wrap: wrap;
        justify-content: left;
      }

      p {
        color: #FFF;
        font-size: 14px;
        font-weight: 300;
        margin: 0;

        @media (max-width: 1099px) {
          width: 100%;
          margin-bottom: 16px;
        }
      }

      ul {
        padding: 0;
        list-style: none;
        display: flex;
        margin: 0

        li {
          display: flex;
          margin-left: 16px;
          &:before {
            content: '|';
            display: block;
            color: #fff;
            font-size: 14px;
            font-weight: 300;
            margin-right: 16px;
          }

          @media (max-width: 1099px) {
            margin-left: 0;
            &:nth-child(1):before {
              display: none;
            }
            &:nth-child(2):before {
              margin-left: 16px;
            }
          }
          
          a {
            color: #FFF;
            font-size: 14px;
            font-weight: 300;
            display: block;
          }
        }
      }
    }
  }

}
  
.footer-badge {
  display flex
  gap 15px
  margin-top 35px;
  align-items center
  img {
    height 60px
  }
}

.footer-badge_text {
  color white
  font-size 12px
  max-width 135px
  @media breakpoints.palm.breakpoint {
    max-width none
  }
}